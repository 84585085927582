<template>
  <Transition name="fade">
    <aside v-if="visible && $beet.options?.popup" class="popup__background">
      <div class="popup">
        <Image v-if="$beet.options.popup.image" :source="$beet.options.popup.image" class="popup__image"/>
        <div class="popup__block">
          <h2 v-if="$beet.options.popup.title" class="popup__title">{{ $beet.options.popup.title }}</h2>
          <h3 v-if="$beet.options.popup.subtitle" class="popup__subtitle">{{ $beet.options.popup.subtitle }}</h3>
        </div>
        <div class="popup__block">
          <a v-if="$beet.options.popup.button.link && $beet.options.popup.button.label"
             :href="$beet.options.popup.button.link" target="_blank" class="popup__button button -light">
            {{ $beet.options.popup.button.label }}
          </a>
          <p v-if="$beet.options.popup.note" class="popup__note">{{ $beet.options.popup.note }}</p>
        </div>
        <button class="popup__close" @click.stop="close">
          <Icon id="close"/>
        </button>
      </div>
    </aside>
  </Transition>
</template>

<script>
import {Icon, Image} from '@/components/global'

export default {
  name: 'Popup',
  components: {Icon, Image},
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false
    }
  },
  mounted() {
    if (this.$beet.options.popup) {
      const start = new Date(this.$beet.options.popup.publish.start)
      const end = new Date(this.$beet.options.popup.publish.end)
      const now = new Date()

      this.visible = start < now && end > now
    }
  }
}
</script>