<template>
  <Transition>
    <Loader v-if="!$beet.isReady"/>
  </Transition>

  <template v-if="$beet.isReady">
    <SiteNav/>
    <router-view v-slot="{ Component }">
      <transition name="fade-fast" mode="out-in">
        <component :is="Component" :key="$route.path"/>
      </transition>
    </router-view>
    <Footer/>
    <Popup/>
  </template>
</template>

<script>
//import {useLangRouter} from '@/extensions/langRouter'
import {Popup} from '@/components/modal'
import {Footer} from '@/components/sections'
import {Loader} from '@/components/small'
import {SiteNav} from '@/components/navigation'

export default {
  name: 'App',
  components: {
    Footer,
    Loader,
    Popup,
    SiteNav
  },
  setup() {
    //useLangRouter()
  },
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    $route: {
      handler(to, from = {}) {
        const delay = to.name !== from.name ? 400 : 0

        setTimeout(() => {
          const el = to.hash ? document.querySelector(to.hash) : null
          if (el) {
            el.scrollIntoView({behavior: 'smooth', block: 'start'})
          } else {
            window.scrollTo({top: 0, behavior: 'smooth'})
          }
        }, delay)
      },
      immediate: true
    }
  }
}
</script>
