<template>
    <section v-if="content.partners?.length" class="partners" data-light-menu="true" data-section="partners"
        id="partners">
        <div class="partners__title">
            <h2>{{ content.title.split(' ').slice(0, 4).join(' ') }}</h2>
            <h2 class="partners__title--bottom">{{ content.title.split(' ').slice(4).join(' ') }}</h2>
        </div>

        <Swiper class="carousel__mobile"
            :slides-per-view="mq.mdPlus ? 3 : 'auto'"
            :loop="true"
            :centeredSlides="content.partners.length > 3"
            :space-between="mq.mdPlus ? 60 : 40"
            :speed="3000"
            :autoplay="autoplayConfig">
            <SwiperSlide class="" v-for="partner in content.partners" :key="partner.flexKey">
                <a class="partners__card" :href="partner.url" target="_blank">
                    <div class="partners__card--logo">
                        <Image :source="partner.logo" />
                    </div>
                    <div class="partners__card--hat">[ <span class="partners__card--hat-middle">{{ partner.name
                            }}</span> ]</div>
                    <div class="partners__card--description">{{ partner.description }}</div>
                    <div class="partners__card--icon">
                        <Icon id="arrow" />
                    </div>
                </a>
            </SwiperSlide>
            <SwiperSlide class="" v-for="partner in content.partners" :key="partner.flexKey">
                <a class="partners__card" :href="partner.url" target="_blank">
                    <div class="partners__card--logo">
                        <Image :source="partner.logo" />
                    </div>
                    <div class="partners__card--hat">[ <span class="partners__card--hat-middle">{{ partner.name
                            }}</span> ]</div>
                    <div class="partners__card--description">{{ partner.description }}</div>
                    <div class="partners__card--icon">
                        <Icon id="arrow" />
                    </div>
                </a>
            </SwiperSlide>
        </Swiper>
    </section>
</template>

<script>
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue'
SwiperCore.use(Autoplay);
export default {
    name: "PartnerSection",
    inject: ['mq'],
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        autoplayConfig() {
            return this.content.partners.length > 3 ? { delay: 1500, disableOnInteraction: false } : false;
        }
    }
}
</script>